import React from 'react'
import { locale } from '../../../src/common/i18n'

interface Props {
  styles: { [k: string]: string }
}

const PoliticaPrivacidadCa = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Política de Privacitat</h1>
    <p>
      La confidencialitat i la seguretat són valors primordials de RODI i, en
      conseqüència, assumim el compromís de garantir la privacitat de l'usuari a
      tot moment i de no recaptar informació innecessària sobre l'Usuari. A
      continuació, li proporcionem tota la informació necessària sobre la nostra
      Política de Privadesa en relació amb les dades personals que recaptem,
      explicant-li:
    </p>

    <ul className={styles.list}>
      <li>Qui és el responsable del tractament de les seves dades.</li>
      <li>Per a quines finalitats recaptem les dades que li sol·licitem.</li>
      <li>Quin és la legitimació per al seu tractament.</li>
      <li>Durant quant temps els conservem.</li>
      <li>A quins destinataris es comuniquen les seves dades.</li>
      <li>Quins són els seus drets.</li>
    </ul>

    <h2 className={styles.section_title}>
      <strong>1. Responsables:</strong>
    </h2>
    <p>RODI METRO, S.L. (B60864311)</p>
    <p>C/ Pere de Cabrera, 16, 5ª planta</p>
    <p>25001 Lleida</p>
    <p>atencionalcliente@rodi.es</p>

    <div className={styles.talleres}>
      <p>
        <strong>RODI CINCASOL, S.L., B25573023 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI URGELL, S.L., B25807280 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI ANOIA, S.L., B61881868 (MANRESA)</strong>
      </p>
      <p>
        <strong>
          RODI SERVEIS INTEGRALS D'AUTOMOCIO, S.L., B61540118 (MANRESA)
        </strong>
      </p>
      <p>
        <strong>RODI ARAN, S.L., B25441866 (VIELHA)</strong>
      </p>
      <p>
        <strong>RODI AREA, S.L., B43742980 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI FRARES, S.L., B25698762 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS ARTIGUES ESTEVE, S.L., B25230632 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI BERGUEDA, S.L., B25458852 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI METRO, S.L., B60864311 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS SOLSONES, S.L., B25066655 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI TERUEL, S.L., B44206654 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI A, S.L.U. (SOC. ANDORRANA), L710003J (ANDORRA)</strong>
      </p>
      <p>
        <strong>MECATERUEL, S.L.L. (FRANQUICIA), B44207843 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI MOIÀ, S.L. (FRANQUICIA), B64374531 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODABAIX, S.L. (FRANQUICIA), B63861231 (BARCELONA)</strong>
      </p>
      <p>
        <strong>RODI PERELLO, S.L. (FRANQUICIA), B43835222 (TARRAGONA)</strong>
      </p>
      <p>
        <strong>REGUERA Y FERRER, S.L. ( FRANQ.), B22387559 (HUESCA)</strong>
      </p>
      <p>
        <strong>
          NEUMATICOS ARMANDO PUEYO, S.L. (FRANQUICIA),&nbsp;KSC B43381862
          (TARRAGONA)
        </strong>
      </p>
      <p>
        <strong>
          EL PASO 2000 TECHNOLOGY, S.L.U., B76177880 (TELDE, LAS PALMAS)
        </strong>
      </p>
      <p>
        <strong>
          NEX TYRES, S.L. Sucursal en Portugal, 980539307 (PORTUGAL)
        </strong>
      </p>
      <p>
        <strong>NEX TYRES, S.L., B25231789 (LLEIDA)</strong>&nbsp;
      </p>
    </div>

    <h2 className={styles.section_title}>
      <strong>
        2. Finalitats, legitimació i conservació dels tractaments de les dades
        enviades a través de:
      </strong>
    </h2>

    <ul className={styles.list}>
      <li>
        <strong>Formulari de contacto.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalitat:</strong> Facilitar-li un mitjà perquè pugui posar-se en
      contacte amb nosaltres i contestar a les seves sol·licituds d'informació,
      així com enviar-li comunicacions dels nostres productes, serveis i
      activitats, inclusivament per mitjans electrònics, si marca la casella
      d'acceptació.
    </p>
    <p>
      <strong>Legitimació:</strong> El consentiment de l'usuari en
      sol·licitar-nos informació a través del nostre formulari de contactes i en
      marcar la casella d'acceptació d'enviament d'informació.
    </p>
    <p>
      <strong>Conservació:</strong> Una vegada resulta la seva sol·licitud per
      mitjà del nostre formulari o contestada per correu electrònic, si no ha
      generat un nou tractament, i en cas d'haver acceptat rebre enviaments
      comercials, fins que sol·liciti la baixa d'aquests.
    </p>

    <ul className={styles.list}>
      <li>
        <strong>Formulari de cita prèvia.</strong>
      </li>
    </ul>

    <p>
      <strong>Finalitat:</strong> Facilitar-li un mitjà per sol·licitar cita als
      nostres tallers, així com enviar-li comunicacions dels nostres productes,
      serveis i activitats, inclusivament per mitjans electrònics, si marca la
      casella d'acceptació.
    </p>
    <p>
      <strong>Legitimació:</strong> El consentiment de l'usuari en
      sol·licitar-nos informació a través del nostre formulari de cita prèvia i
      en marcar la casella d'acceptació d'enviament d'informació.
    </p>
    <p>
      <strong>Conservació:</strong> Fins a la data de la cita, si no ha generat
      un nou tractament. En cas d'haver acceptat rebre enviaments comercials,
      fins que sol·liciti la baixa d'aquests.
    </p>

    <ul className={styles.list}>
      <li>
        <strong>Formulari de registre.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalitat:</strong> Donar-li d'alta com a usuari i permetre'ls
      l'accés i la contractació dels serveis de la nostra web i informar-lo de
      les nostres activitats, productes i serveis, així com enviar-li
      comunicacions comercials dels nostres productes, serveis inclusivament per
      mitjans electrònics), si marca la casella d'acceptació.
    </p>
    <p>
      <strong>Legitimació:</strong> El consentiment de l'usuari en registrar-se
      a través del nostre formulari de registre i en marcar la casella
      d'acceptació d'enviament d'informació.
    </p>
    <p>
      <strong>Conservació:</strong> Fins que l'usuari registrat sol·liciti la
      seva baixa i posteriorment durant els terminis legalment previstos.
    </p>

    <ul className={styles.list}>
      <li>
        <strong>Enviament de correus electrònics.</strong>
      </li>
    </ul>

    <p>
      <strong>Finalitat:</strong> Contestar a les seves sol·licituds
      d'informació, atendre les seves peticions i respondre les seves consultes
      o dubtes.
    </p>
    <p>
      <strong>Legitimació:</strong> El consentiment de l'usuari en
      sol·licitar-nos informació a través de l'adreça de correu electrònic.
    </p>
    <p>
      <strong>Conservació:</strong> Una vegada resulta contestada la seva
      petició per correu electrònic, si no ha generat un nou tractament.
    </p>

    <ul className={styles.list}>
      <li>
        <strong>Enviament del currículum vitae per correu electrònic.</strong>
      </li>
    </ul>

    <p>
      <strong>Finalitat:</strong> Disposar del seu currículum per participar en
      els nostres processos de selecció de personal.
    </p>
    <p>
      <strong>Legitimació:</strong> El consentiment de l'usuari en remetre'ns la
      seva informació personal i currículum per als nostres processos de
      selecció de personal.
    </p>
    <p>
      <strong>Conservació:</strong> Durant el desenvolupament dels processos de
      selecció de personal oberts i durant 1 any per a futurs processos.
    </p>

    <ul className={styles.list}>
      <li>
        <strong>Subscripció a nostra newsletter</strong>
      </li>
    </ul>

    <p>
      <strong>Finalitat:</strong> Enviament del nostre butlletí comercial i de
      comunicacions informatives i publicitàries sobre els nostres productes o
      serveis que siguin del seu interès, fins i tot per mitjans electrònics.
    </p>
    <p>
      Legitimació: El consentiment de l'usuari en subscriure's als nostres
      enviaments comercials i/o newsletters.
    </p>
    <p>
      <strong>Conservació:</strong> Fins que l'interessat arrebossat el
      consentiment i sol·liciti la baixa del servei.
    </p>
    <p>
      <strong>
        Obligació de facilitar-nos les seves dades personals i conseqüències de
        no fer-ho.
      </strong>
    </p>
    <p>
      El subministrament de dades personals requereix una edat mínima de 13
      anys, o si escau, disposar de capacitat jurídica suficient per contractar.
    </p>
    <p>
      Les dades personals sol·licitades són necessàries per gestionar les seves
      sol·licituds i/o prestar-li els serveis que pugui contractar, per la qual
      cosa, si no ens els facilita, no podrem atendre-li correctament ni
      prestar-li el servei que ha sol·licitat.
    </p>

    <h2 className={styles.section_title}>
      <strong>3. Destinatari de les seves dades</strong>
    </h2>

    <p>
      Les seves dades són confidencials i no es cediran a tercers, llevat que hi
      hagi obligació legal i, en els següents supòsits:
    </p>

    <p>
      - Les seves dades poden ser accessibles per qualsevol de les societats del
      GRUP RODI indicades en l'apartat 1., titulars de tallers i punts de venda
      RODI, amb la finalitat que pugui acudir a qualsevol dels nostres tallers o
      punts de venda i que li puguin prestar, independentment de la zona
      geogràfica en què es trobi, serveis personalitzats i podrà accedir al seu
      historial de reparacions.
    </p>

    <p>
      - Així mateix, la cessió es realitza perquè en nom del GRUP RODI, pugui
      rebre la newsletter corporativa i totes les ofertes i descomptes que
      oferim als nostres clients i usuaris, independentment del taller en què
      reparin o comprin productes habitualment.
    </p>

    <h2 className={styles.section_title}>
      <strong>4. Drets en relació amb les seves dades personals</strong>
    </h2>

    <p>
      Qualsevol persona pot retirar el seu consentiment a qualsevol moment, quan
      el mateix s'hagi atorgat per al tractament de les seves dades. En cap cas,
      la retirada d'aquest consentiment condiciona l'execució del contracte de
      subscripció o les relacions generades amb anterioritat.
    </p>
    <p>Igualment, pot exercir els següents drets:</p>

    <ul className={styles.list}>
      <li>
        Sol·licitar l'accés a les seves dades personals o la seva rectificació
        quan siguin inexactes.
      </li>
      <li>
        Sol·licitar la seva supressió quan, entre altres motius, les dades ja no
        siguin necessaris per a les finalitats pels quals van ser recollits.
      </li>
      <li>
        Sol·licitar la limitació del seu tractament en determinades
        circumstàncies.
      </li>
      <li>
        Sol·licitar l'oposició al tractament de les seves dades per motius
        relacionats amb la seva situació particular.
      </li>
      <li>
        Sol·licitar la portabilitat de les dades en els casos previstos en la
        normativa.
      </li>
      <li>Altres drets reconeguts en les normatives aplicables.</li>
    </ul>

    <p>
      On i com sol·licitar els seus Drets: Mitjançant un escrit dirigit al
      responsable a la seva adreça postal o electrònica (indicades a l'apartat
      A), indicant la referencia "Dades Personals", especificant el dret que es
      vol exercir i respecte a quines dades personals.
    </p>
    <p>
      En cas de divergències amb l'empresa en relació amb el tractament de les
      seves dades, pot presentar una reclamació davant l'Autoritat de Protecció
      de Dades (www.agpd.es).
    </p>

    <h2 className={styles.section_title}>
      <strong>5. Cookies</strong>
    </h2>
    <p>
      Aquesta pàgina web, únicament utilitza cookies tècniques, de
      personalització i anàlisi, pròpies i de tercers (Google Analytics), que en
      cap cas tracten dades de caràcter personal ni capten hàbits de navegació
      per a finalitats publicitàries.
    </p>
    <p>
      Per això, en accedir a la nostra web, en compliment de l'article 22 de la
      Llei de Serveis de la Societat de la Informació, en tractar cookies
      d'anàlisi, li hem sol·licitat el seu consentiment per al seu ús i
      facilitat informació d'aquestes, que en tot cas s'instal·laran passat un
      termini de temps prudencial perquè l'usuari tingui temps de decidir
      prestar el seu consentiment o no.
    </p>

    <h2 className={styles.section_title}>
      <strong>6. Seguretat de les seves dades personals</strong>
    </h2>
    <p>
      Amb l'objectiu de salvaguardar la seguretat de les seves dades personals,
      l'informem que hem adoptat totes les mesures d'índole tècnica i
      organitzativa necessàries per garantir la seguretat de les dades personals
      subministrades de la seva alteració, pèrdua, i tractaments o accessos no
      autoritzats.
    </p>

    <h2 className={styles.section_title}>
      <strong>7. Actualització de les seves dades</strong>
    </h2>
    <p>
      És important que perquè puguem mantenir les seves dades personals
      actualitzades, ens informi sempre que hi hagi hagut alguna modificació en
      ells, en cas contrari, no responem de la veracitat d'aquests.
    </p>
    <p>
      No ens fem responsables de la política de privadesa respecte a les dades
      personals que pugui facilitar a tercers per mitjà dels enllaços
      disponibles a la nostra pàgina web.
    </p>
    <p>
      La present Política de Privadesa pot ser modificada per adaptar-les als
      canvis que es produeixi a la nostra web, així com modificacions
      legislatives o jurisprudencials sobre dades personals que vagin
      apareixent, per la qual cosa exigeix la seva lectura, cada vegada que ens
      faciliti les seves dades a través d'aquesta Web.
    </p>
  </div>
)

const PoliticaPrivacidadEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Política de Privacidad</h1>
    <p>
      La confidencialidad y la seguridad son valores primordiales de RODI y, en
      consecuencia, asumimos el compromiso de garantizar la privacidad del
      Usuario en todo momento y de no recabar información innecesaria. A
      continuación, le proporcionamos toda la información necesaria sobre
      nuestra Política de Privacidad en relación con los datos personales que
      recabamos, explicándole:
    </p>
    <ul className={styles.list}>
      <li>Quién es el responsable del tratamiento de sus datos.</li>
      <li>Para qué finalidades recabamos los datos que le solicitamos.</li>
      <li>Cuál es la legitimación para su tratamiento.</li>
      <li>Durante cuánto tiempo los conservamos.</li>
      <li>A qué destinatarios se comunican sus datos.</li>
      <li>Cuáles son sus derechos.</li>
    </ul>
    <h2 className={styles.section_title}>
      <strong>1.&nbsp;Responsables</strong>:
    </h2>
    <p>RODI METRO, S.L. (B60864311)</p>
    <p>C/ Pere de Cabrera, 16, 5ª planta</p>
    <p>25001 Lleida</p>
    <p>atencionalcliente@rodi.es</p>
    <div className={styles.talleres}>
      <p>
        <strong>RODI CINCASOL, S.L., B25573023 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI URGELL, S.L., B25807280 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI ANOIA, S.L., B61881868 (MANRESA)</strong>
      </p>
      <p>
        <strong>
          RODI SERVEIS INTEGRALS D'AUTOMOCIO, S.L., B61540118 (MANRESA)
        </strong>
      </p>
      <p>
        <strong>RODI ARAN, S.L., B25441866 (VIELHA)</strong>
      </p>
      <p>
        <strong>RODI AREA, S.L., B43742980 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI FRARES, S.L., B25698762 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS ARTIGUES ESTEVE, S.L., B25230632 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI BERGUEDA, S.L., B25458852 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI METRO, S.L., B60864311 (LLEIDA)</strong>
      </p>
      <p>
        <strong>PNEUMATICS SOLSONES, S.L., B25066655 (LLEIDA)</strong>
      </p>
      <p>
        <strong>RODI TERUEL, S.L., B44206654 (TERUEL)</strong>
      </p>
      <p>
        <strong>RODI A, S.L.U. (SOC. ANDORRANA), L710003J (ANDORRA)</strong>
      </p>
    </div>

    <h2 className={styles.section_title}>
      <strong>
        2. Finalidades, legitimación y conservación de los tratamientos de los
        datos enviados a través de:
      </strong>
    </h2>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de contacto.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Facilitarle un medio para que pueda ponerse en
      contacto con nosotros y contestar a sus solicitudes de información, así
      como enviarle comunicaciones de nuestros productos, servicios y
      actividades, inclusive por medios electrónicos, si marca la casilla de
      aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de nuestro formulario de contactos y al
      marcar la casilla de aceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta una vez resulta su solicitud por
      medio de nuestro formulario o contestada por correo electrónico, si no ha
      generado un nuevo tratamiento. En caso de haber aceptado recibir envíos
      comerciales, hasta que solicite la baja de los mismos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de cita previa.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Facilitarle un medio para solicitar cita en
      nuestros talleres, así como enviarle comunicaciones de nuestros productos,
      servicios y actividades, inclusive por medios electrónicos, si marca la
      casilla de aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de nuestro formulario de cita previa y
      al marcar la casilla de aceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta la fecha de la cita si no ha generado
      un nuevo tratamiento. En caso de haber aceptado recibir envíos
      comerciales, hasta que solicite la baja de los mismos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de registro.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Darle de alta como usuario y permitirles el
      acceso y la contratación de los servicios de nuestra web e informarle de
      nuestras actividades, productos y servicios, así como enviarle
      comunicaciones comerciales de nuestros productos, servicios inclusive por
      medios electrónicos), si marca la casilla de aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      registrarse a través de nuestro formulario de registro y al marcar la
      casilla de aceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta que el usuario registrado solicite su
      baja y posteriormente durante los plazos legalmente previstos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Envío de correos electrónicos.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Contestar a sus solicitudes de información,
      atender sus peticiones y responder sus consultas o dudas.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de la dirección de correo electrónico.
    </p>
    <p>
      <strong>Conservación:</strong> Una vez resulta contestada su petición por
      correo electrónico, si no ha generado un nuevo tratamiento.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Envío del currículum vitae por correo electrónico.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Disponer de su currículum para participar en
      nuestros procesos de selección de personal.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al remitirnos
      su información personal y currículum para nuestros procesos de selección
      de personal.
    </p>
    <p>
      <strong>Conservación:</strong> Durante el desarrollo de los procesos de
      selección de personal abiertos y durante 1 año para futuros procesos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Suscripción a nuestra newsletter</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Envío de nuestro boletín comercial y de
      comunicaciones informativas y publicitarias sobre nuestros productos o
      servicios que sean de su interés, incluso por medios electrónicos.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      suscribirse a nuestros envíos comerciales y/o newsletters.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta que el interesado revoque el
      consentimiento y solicite la baja del servicio.
    </p>
    <p>
      <strong>
        Obligación de facilitarnos sus datos personales y consecuencias de no
        hacerlo.
      </strong>
    </p>
    <p>
      El suministro de datos personales requiere una edad mínima de 14 años, o
      en su caso, disponer de capacidad jurídica suficiente para contratar.
    </p>
    <p>
      Los datos personales solicitados son necesarios para gestionar sus
      solicitudes y/o prestarle los servicios que pueda contratar, por lo que,
      si no nos los facilita, no podremos atenderle correctamente ni prestarle
      el servicio que ha solicitado.
    </p>
    <h2 className={styles.section_title}>
      <strong>3. Destinatario de sus datos</strong>
    </h2>
    <p>
      Sus datos son confidenciales y no se cederán a terceros, salvo que exista
      obligación legal y, en los siguientes supuestos:
    </p>
    <p>
      - Sus datos pueden ser accesibles por cualquiera de las sociedades del
      GRUPO RODI indicadas en el apartado 1., titulares de talleres y puntos de
      venta RODI, con la finalidad de que pueda acudir a cualquiera de nuestros
      talleres o puntos de venta y que le puedan prestar, independientemente de
      la zona geográfica en que se encuentre, servicios personalizados pudiendo
      acceder a su historial de reparaciones.
    </p>
    <p>
      - Asimismo, la cesión se realiza para que en nombre del GRUPO RODI, pueda
      recibir la newsletter corporativa y todas las ofertas y descuentos que
      ofrezcamos a nuestros clientes y usuarios, independientemente del taller
      en que reparen o compren productos habitualmente.
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Derechos en relación con sus datos personales</strong>
    </h2>
    <p>
      Cualquier persona puede retirar su consentimiento en cualquier momento,
      cuando el mismo se haya otorgado para el tratamiento de sus datos. En
      ningún caso, la retirada de este consentimiento condiciona la ejecución
      del contrato de suscripción o las relaciones generadas con anterioridad.
    </p>
    <p>Igualmente, puede ejercer los siguientes derechos:</p>
    <ul className={styles.list}>
      <li>
        Solicitar el acceso a sus datos personales o su rectificación cuando
        sean inexactos.
      </li>
      <li>
        Solicitar su supresión cuando, entre otros motivos, los datos ya no sean
        necesarios para los fines para los que fueron recogidos.
      </li>
      <li>
        Solicitar la limitación de su tratamiento en determinadas
        circunstancias.
      </li>
      <li>
        Solicitar la oposición al tratamiento de sus datos por motivos
        relacionados con su situación particular.
      </li>
      <li>
        Solicitar la portabilidad de los datos en los casos previstos en la
        normativa.
      </li>
      <li>Otros derechos reconocidos en las normativas aplicables.</li>
    </ul>
    <p>
      Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido al
      responsable a su dirección postal o electrónica (indicadas en el apartado
      A), indicando la referencia “Datos Personales”, especificando el derecho
      que se quiere ejercer y respecto a qué datos personales.
    </p>
    <p>
      En caso de divergencias con la empresa en relación con el tratamiento de
      sus datos, puede presentar una reclamación ante la Autoridad de Protección
      de Datos (www.agpd.es).
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Cookies</strong>
    </h2>
    <p>
      Esta página web, únicamente utiliza cookies técnicas, de personalización y
      análisis, propias y de terceros (Google Analytics), que en ningún caso
      tratan datos de carácter personal ni captan hábitos de navegación para
      fines publicitarios.
    </p>
    <p>
      Por ello, al acceder a nuestra web, en cumplimiento del artículo 22 de la
      Ley de Servicios de la Sociedad de la Información, al tratar cookies de
      análisis, le hemos solicitado su consentimiento para su uso y facilitado
      información de las mismas, que en todo caso se instalarán pasado un plazo
      de tiempo prudencial para que el usuario tenga tiempo de decidir prestar
      su consentimiento o no.
    </p>
    <h2 className={styles.section_title}>
      <strong>6. Seguridad de sus datos personales</strong>
    </h2>
    <p>
      Con el objetivo de salvaguardar la seguridad de sus datos personales, le
      informamos que hemos adoptado todas las medidas de índole técnica y
      organizativa necesarias para garantizar la seguridad de los datos
      personales suministrados de su alteración, pérdida, y tratamientos o
      accesos no autorizados.
    </p>
    <h2 className={styles.section_title}>
      <strong>7. Actualización de sus datos</strong>
    </h2>
    <p>
      Es importante que para que podamos mantener sus datos personales
      actualizados, nos informe siempre que haya habido alguna modificación en
      ellos, en caso contrario, no respondemos de la veracidad de los mismos.
    </p>
    <p>
      No nos hacemos responsables de la política de privacidad respecto a los
      datos personales que pueda facilitar a terceros por medio de los enlaces
      disponibles en nuestra página web.
    </p>
    <p>
      La presente Política de Privacidad puede ser modificada para adaptarlas a
      los cambios que se produzca en nuestra web, así como modificaciones
      legislativas o jurisprudenciales sobre datos personales que vayan
      apareciendo, por lo que exige su lectura, cada vez que nos facilite sus
      datos a través de esta Web.
    </p>
  </div>
)

const PoliticaPrivacidadContents = ({ ...props }: Props) =>
  locale === 'es' ? (
    <PoliticaPrivacidadEs {...props} />
  ) : (
    <PoliticaPrivacidadCa {...props} />
  )

export default PoliticaPrivacidadContents
